import React, { Fragment, useEffect } from "react";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import pptxgen from 'pptxgenjs';
import PptxGenJS from "pptxgenjs";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CommentItem from "./CommentItem";
import CommentForm from "./CommentForm";
import EditComment from "./EditComment";
import FormWizardGaza from "../from/form-wizard-gaza";
import { addLike, removeLike, deletePost } from "../../../actions/post";
import { savePost } from "../../../actions/profile";
import { loadUser } from "../../../actions/auth";
import { getPosts, getPost } from "../../../actions/post";
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
// import EditPost from "./EditPost";
// import PostForm from "./PostForm";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
// import { ImageGroup, Image } from "react-fullscreen-image";
import teethNumbers from "../../../assets/images/deethNumbers.png";
import pdficon from "../../../assets/images/icon/pdficon.jpg"
import powerpointicon from "../../../assets/images/icon/powerpointicon.png"
import { Icon } from "@iconify/react";
// creating functional component ans getting props from app.js and destucturing them
import { Row, Col, Container, Dropdown, Button } from "react-bootstrap";
import Card from "../../../components/Card";
const PostDetails = ({ match, getPost,   addLike,
  removeLike,
  deletePost,
  savePost,
  loadUser, 

}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80em",
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };

  const TeethStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40em",
    bgcolor: "background.paper",
    textAlign: "center",
    boxShadow: 24,
    p: 4,
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
   
    height: "80%",
    overflowY: 'scroll', 
  };

  const [openEditPost, setOpenEditPost] = React.useState(false);
  const handleOpenEditPost = () => setOpenEditPost(true);
  const handleCloseEditPost = () => setOpenEditPost(false);

  const BloodPressureLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
      backgroundColor: postInfo.bloodPressure < 120 ? "#a6ce39" : "#bb3a01",
    },
  }));

  const PulseLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
      backgroundColor: postInfo.pulse < 74 ? "#a6ce39" : "#bb3a01",
    },
  }));

  const RespirationLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
      backgroundColor: postInfo.respiration < 45 ? "#a6ce39" : "#bb3a01",
    },
  }));

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const { pathname } = window.location;
  const id = pathname.split("/").pop();


  const { myid } = useParams();
  //   useEffect(() => {
  //     getPost(id);
  //     console.log("hello", id);
  //   }, [getPost, id]);

  useEffect(() => {
    getPost(id);
    
  }, [id, myid]);



  const post = useSelector((state) => state.posts.post);

  // const filteredpost = posts.filter((post) => post._id === id);
  const postDetails = post;
  const postInfo = post?.postInfo;
  const _id = post?._id;
  const user = post?.user;


  // console.log("rendered post?", post);

  const images = [
    `${process.env.REACT_APP_BASE_URL + "/" + postInfo?.postImage}`,
    // "https://www.barkerdentalcare.co.uk/wp-content/uploads/2016/12/CI2-decay.jpg",
    // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS53-plCXj8yvWBSCyebWWuaiHQvPiMo3KfZcpsycLM4ISZpn5Mgd74hBJy85AtPI69VzQ&usqp=CAU",
  ];

  //   for (var i = 0; i < postInfo.postImages.length; i++) {
  //     images.push(postImages[i]?.post);
  //   }

  //Modal options
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  const categories = {
    radiopanoramic: "Radio Panoramic",
    conebeam: "Cone Beam",
    endobuccale: "Endobuccale",
    vueface: "Vue Face",
    vueprofil: "Vue Profil",
    teleradioprofil: "Teleradio Profil",
  };


  var auth = useSelector((state) => state.profile.profiles[0]);
  var myauth = useSelector((state) => state.auth.user);

//TO PDF START
  const options = {
    // default is `save`
    method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: Margin.SMALL,
       // default is 'A4'
       format: 'letter',
       // default is 'portrait'
       orientation: 'landscape',
    },
    canvas: {
       // default is 'image/jpeg' for better size performance
       mimeType: 'image/jpeg',
       qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
       // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
       pdf: {
          compress: true
       },
       // see https://html2canvas.hertzen.com/configuration for more options
       canvas: {
          useCORS: true
       }
    },
 };
 
 // you can use a function to return the target element besides using React refs
 const getTargetElement = () => document.getElementById('postDetailsContainer');
//TO PDF END



  return !postInfo ? (
    <loader />
  ) : (
    <Fragment>
          <Container>
          <Row>
          <Col lg={12}>
            <Card className="postDetailsExport">
            <div className="exportIconsContainer" style={{float: "left"}}>
             <h3 >Export</h3>
             <img src={pdficon} alt="exportPDF" style={{width: 25, cursor: "pointer"}} onClick={() => generatePDF(getTargetElement, options)} />
              </div>
              
              <div className="card-post-toolbar" style={{float: "right"}}>
                    <Dropdown>
                      <Dropdown.Toggle variant="bg-transparent">
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                        <Dropdown.Item
                          className=" p-3"
                          to="#"
                          onClick={() => {
                            savePost(_id);
                            loadUser();
                            loadUser();
                          }}
                        >
                          {_id &&
                            myauth?.savedPosts?.filter(
                              (savedPost) => savedPost?.post?.toString() === _id
                            ).length === 0 && (
                              <div className="d-flex align-items-top">
                                <div className="h4 material-symbols-outlined">
                                  <i className="ri-save-line"></i>
                                </div>
                                <div className="data ms-2">
                                  <h6>Save Post</h6>
                                  <p className="mb-0">
                                    Add this to your saved items
                                  </p>
                                </div>
                              </div>
                            )}

                          {_id &&
                            myauth?.savedPosts?.filter(
                              (savedPost) => savedPost?.post?.toString() === _id
                            ).length !== 0 && (
                              <div className="d-flex align-items-top">
                                <div className="h4 material-symbols-outlined">
                                  <i className="ri-save-line"></i>
                                </div>
                                <div className="data ms-2">
                                  <h6>Unsave Post</h6>
                                  <p className="mb-0">
                                    Remove this from your saved items
                                  </p>
                                </div>
                              </div>
                            )}
                        </Dropdown.Item>
                        { user === myauth?._id  && 
                        <Dropdown.Item className="p-3" to="#"   onClick={() => {
                          handleOpenEditPost(_id);
                        
                        }}>
                          <div className="d-flex align-items-top">
                            <i className="ri-edit-line h4"></i>
                            <div className="data ms-2">
                              <h6>Edit Post</h6>
                              <p className="mb-0">Edit your post.</p>
                            </div>
                          </div>
                        </Dropdown.Item>}
                        { user === myauth._id  && 
                        <Dropdown.Item className="p-3" to="#"   onClick={() => {
                          deletePost(_id);
                        
                        }}>
                          <div className="d-flex align-items-top">
                            <i className="ri-close-circle-line h4"></i>
                            <div className="data ms-2">
                              <h6>Delete Post</h6>
                              <p className="mb-0">Delete your post.</p>
                            </div>
                          </div>
                        </Dropdown.Item>}
                        <Modal
        open={openEditPost}
        onClose={handleCloseEditPost}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <FormWizardGaza
            _id={_id}
            postInfo={postInfo}
            getPost={getPost}
            match={match}
            handleCloseEditPost={handleCloseEditPost}
            editPostMode={true}
          />
          {/* <PostForm _id={_id} postInfo={postInfo}  getPost={getPost} match={match} handleCloseEditPost={handleCloseEditPost} editMode={true}/> */}
        </Box>
      </Modal>
                        {/* <Dropdown.Item className="p-3" to="#">
                          <div className="d-flex align-items-top">
                            <i className="ri-close-circle-line h4"></i>
                            <div className="data ms-2">
                              <h6>Hide Post</h6>
                              <p className="mb-0">See fewer posts like this.</p>
                            </div>
                          </div>
                        </Dropdown.Item> */}
                        {/* <Dropdown.Item className=" p-3" to="#">
                          <div className="d-flex align-items-top">
                            <i className="ri-user-unfollow-line h4"></i>
                            <div className="data ms-2">
                              <h6>Unfollow User</h6>
                              <p className="mb-0">
                                Stop seeing posts but stay friends.
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className=" p-3" to="#">
                          <div className="d-flex align-items-top">
                            <i className="ri-notification-line h4"></i>
                            <div className="data ms-2">
                              <h6>Notifications</h6>
                              <p className="mb-0">
                                Turn on notifications for this post
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
              
           
             
            </Card>
          </Col>
          </Row>
</Container>
      <Container id="postDetailsContainer" >
      
      
      
          <Row>
        <Col lg={4}>
          {/* {postInfo?.postImage &&   <Card>
            <div>
              <img src={ `${process.env.REACT_APP_BASE_URL + "/" + postInfo?.postImage}`} alt="dentistupPost" id="postPhoto"></img>
            </div>
            </Card>} */}
             {/* {imageFields.map((fieldName) => (
      <Card key={fieldName}>
        <div>
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${postInfo?.postImages[fieldName]}`}
            alt={`${fieldName}`}
            id="postPhoto"
          />
        </div>
      </Card>
    ))} */}

{/* {Object.entries(postInfo?.postImages).map(([fieldName, imagePath]) => (
      imagePath && (
        <Card key={fieldName}>
          <div>
            <img
              src={`${process.env.REACT_APP_BASE_URL}/${imagePath}`}
              alt={`${fieldName}`}
              id="postPhoto"
            />
          </div>
        </Card>
      )
    ))} */}
    <Card >
              <div >
                <p className="my-1">
                  <h3>Patient</h3>
                  <span>Référence </span>
                  {postInfo.patientReference}{" "}
                </p>
                <p className="my-1">
                  <div className="postIconsInfo">
                    <span style={{ marginRight: 5, marginLeft: 5, marginTop: "-0.2em" }}>Age </span>{" "}
                    {getAge(postInfo.dateOfBirth)}{" "}
                    {postInfo.gender === "male" && (
                      <i
                        class="fa-solid fa-mars"
                        style={{ color: "#4e9ec6" }}
                      ></i>
                    )}
                    {postInfo.gender === "female" && (
                      <i
                        class="fa-solid fa-venus"
                        style={{ color: "#f98196" }}
                      ></i>
                    )}
                  </div>
                </p>
                <p className="my-1">
                  <span>Date of birth </span> {postInfo.dateOfBirth}{" "}
                </p>
                <div className="postDetailsDescTwoRight">
                  {postInfo.bloodPressure.length > 0 && <div> 
                    <p className="my-1">
                    {" "}
                    <span>Blood Pressure </span>{" "}
                    <i
                      class="fa-solid fa-heart-pulse"
                      style={{ color: "#4e9ec6" }}
                    ></i>{" "}
                    {postInfo.bloodPressure} mm Hg
                  </p>
                  <div style={{ width: "50%" }}>
                    {" "}
                    <BloodPressureLinearProgress
                      variant="determinate"
                      value={(postInfo.bloodPressure - 30 ) / (250 - 30) * 100}
                    />
                  </div>
                    </div>}
                


                {postInfo.pulse.length > 0 && <div> 
                  
                  <p className="my-1">
                    <span>Pulse </span>{" "}
                    <i
                      class="fa-solid fa-droplet"
                      style={{ color: "#4e9ec6" }}
                    ></i>{" "}
                    {postInfo.pulse} Beats/min
                  </p>
                  <div style={{ width: "50%" }}>
                    {" "}
                    <PulseLinearProgress
                      variant="determinate"
                      value={(postInfo.pulse - 30) / (250 - 30) * 100}
                    />
                  </div>
                  </div>}
                  

{postInfo.respiration.length > 0 && 


<div> 
                  
<p className="my-1">
                    <span>Breathing </span>{" "}
                    <i
                      class="fa-solid fa-lungs"
                      style={{ color: "#4e9ec6" }}
                    ></i>{" "}
                    {postInfo.respiration} Breaths/min
                  </p>
                  <div style={{ width: "50%" }}>
                    {" "}
                    <RespirationLinearProgress
                      variant="determinate"
                      value={(postInfo.respiration - 5) / (80 - 5) * 100}
                    />
                  </div>
                  </div>
}

              
                </div>
              </div>
            </Card>
{Object.entries(categories).map(([categoryKey, categoryTitle]) => {
     const categoryImages = Object.entries(postInfo?.postImages || {}).filter(([fieldName, imagePath]) => {
        return fieldName.includes(categoryKey) && imagePath;
      });

      if (categoryImages.length === 0) {
        return null; // Skip rendering the category card if no images exist
      }

      return (
        <Card key={categoryKey}>
          <div>
            <h4>Picture</h4>
            <div>
              {categoryImages.map(([fieldName, imagePath]) => (
                <div key={fieldName}>
                 
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${imagePath}`}
                    alt={`${fieldName}`}
                    id="postPhoto"
                  />
                </div>
              ))}
            </div>
          </div>
        </Card>
      );
    })}
        
          </Col>
          <Col lg={8}>
          <Card>
            <Card.Body className=" profile-page p-0">
              <h3 className="my-1">{postInfo.title} </h3>
              <p className="my-1" id="postDescription">
                {" "}
                {postInfo.description}{" "}
              </p>

            </Card.Body>
          </Card>
            <Card>
              <p className="my-1">
                <span>Reason of consultation</span> <br />
                {postInfo.reasonConsultation}
              </p>

              <div className="postDetailsDescTwoLeft">
                <p className="my-1">
                  <span>Medical History :</span> {postInfo.medicalHistory}{" "}
                </p>
           
                <p className="my-1">
                  <span>Medication :</span> {postInfo.dailyMedications}{" "}
                </p>

            
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={TeethStyle}>
                  <img
                    src={teethNumbers}
                    alt="teeth"
                    style={{ width: "30em", textAlign: "center" }}
                  />
                </Box>
              </Modal>
            </Card>
            <Card>
              <h3>General Examinations</h3>
              <p className="my-1">
                <span>Examination :</span>{" "}
                {postInfo.extraoralExamination}{" "}
              </p>
              <p className="my-1">
                <span>Investigation :</span>{" "}
                {postInfo.examenExoBuccal}{" "}
              </p>
              <p className="my-1">
                <span>Evolution under treatment :</span> {postInfo.dermato}{" "}
              </p>
             

          
            </Card>
       
           


            <Card>
              <h3>Analysis</h3>
              

             

              
                <p className="my-1">
                  <span>Case Diagnostics :</span> {postInfo?.casediagnostics}{" "}
                </p>
              

              
               <p className="my-1">
                <span>Treatment Plan :  {postInfo?.treatmentplan}{" "}</span> 
                </p>
           
{/*
                {postInfo?.sessions?.map((session, index) => (
        <div key={index}>
          <h3>{session?.name}</h3>
          <p>Treatment Plan: {session?.treatmentplan}</p>
          
        </div>
      ))}
              </p> */}

             
            </Card>
          </Col>
       
        </Row>

        {/* <Row>
          <Col lg={4}>
            <Card>
              <h3>Examens Généraux</h3>
              <p className="my-1">
                <span>Extraoral Examination :</span>{" "}
                {postInfo.extraoralExamination}{" "}
              </p>
              <p className="my-1">
                <span>Intraoral Examination :</span>{" "}
                {postInfo.intraoralExamination}{" "}
              </p>
              <p className="my-1">
                <span>Examen exo-buccal :</span> {postInfo.examenExoBuccal}{" "}
              </p>
              <p className="my-1">
                <span>Dermato :</span> {postInfo.dermato}{" "}
              </p>
              <p className="my-1">
                <span>Symétrie :</span>{" "}
                {postInfo.symetrie === "ouiSymetrie" ? (
                  <CheckCircleIcon />
                ) : (
                  <UnpublishedIcon />
                )}{" "}
              </p>

              {postInfo.symetrie === "ouiSymetrie" && (
                <p className="my-1">
                  <span>Détails Symétrie :</span> {postInfo.symetrieExplanation}{" "}
                </p>
              )}
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <h3>
                <span></span>Examen des ATM
              </h3>

              {postInfo.examenAtmNormal === "true" && (
                <p className="my-1">
                  <span>Normal :</span>{" "}
                  {postInfo.examenAtmNormal === "true" ? (
                    <CheckCircleIcon />
                  ) : (
                    <UnpublishedIcon />
                  )}
                </p>
              )}

              {postInfo.examenAtmDouleur === "true" && (
                <p className="my-1">
                  <span>Douleur :</span>{" "}
                  {postInfo.examenAtmDouleur === "true" ? (
                    <CheckCircleIcon />
                  ) : (
                    <UnpublishedIcon />
                  )}
                </p>
              )}

              {postInfo.examenAtmClaquement === "true" && (
                <p className="my-1">
                  <span>Claquement :</span>{" "}
                  {postInfo.examenAtmClaquement === "true" ? (
                    <CheckCircleIcon />
                  ) : (
                    <UnpublishedIcon />
                  )}
                </p>
              )}

              {postInfo.examenAtmAutre === "true" && (
                <p className="my-1">
                  <span>Autres :</span>{" "}
                  {postInfo.examenAtmAutre === "true" ? (
                    <CheckCircleIcon />
                  ) : (
                    <UnpublishedIcon />
                  )}
                </p>
              )}

              {postInfo.examenAtmAutre.length > 1 && (
                <p className="my-1">
                  <span>Explication :</span>{" "}
                  {postInfo.examenAtmAutreExplanation}{" "}
                </p>
              )}
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <h3>Examens Fonctionnels</h3>
              <h4>Respiration</h4>

              <p className="my-1">
                <span>Type :</span> {postInfo.respirationType}{" "}
              </p>

              {postInfo.detailsRespiration.length > 1 && (
                <p className="my-1">
                  <span>Détails :</span> {postInfo.detailsRespiration}{" "}
                </p>
              )}

              <h4>Mastication</h4>
              <p className="my-1">
                <span>Type :</span> {postInfo.mastication}{" "}
              </p>

              {postInfo.detailsMastication.length > 1 && (
                <p className="my-1">
                  <span>Détails :</span> {postInfo.detailsMastication}{" "}
                </p>
              )}

              <h4>Déglutition</h4>

              <p className="my-1">
                <span>Type :</span> {postInfo.deglutition}{" "}
              </p>
              {postInfo.detailsDeglutition.length > 1 && (
                <p className="my-1">
                  <span>Détails :</span> {postInfo.detailsDeglutition}{" "}
                </p>
              )}
            </Card>
          </Col>
        </Row> */}
      </Container>

      <div style={{ display: "none" }}>
        <div id="postDetailsContainer">
          <h2 className="my-1">{postInfo.title} </h2>
          <p className="my-1" id="postDescription">
            {" "}
            {postInfo.description}{" "}
          </p>
          <div className="threeColumns">
            <div className="gridOne">
              <div>
                <p className="my-1">
                  <span>Reason of consultation</span> <br />
                  {postInfo.reasonConsultation}
                </p>
              </div>
              <div>
                <p className="my-1">
                  <h3>Patient</h3>
                  <span>Référence </span>
                  {postInfo.patientReference}{" "}
                </p>
                <p className="my-1">
                  <div className="postIconsInfo">
                    <span>Age </span> {getAge(postInfo.dateOfBirth)}{" "}
                    {postInfo.gender === "male" && (
                      <i
                        class="fa-solid fa-mars"
                        style={{ color: "#4e9ec6" }}
                      ></i>
                    )}
                    {postInfo.gender === "female" && (
                      <i
                        class="fa-solid fa-venus"
                        style={{ color: "#f98196" }}
                      ></i>
                    )}
                  </div>
                </p>
                <p className="my-1">
                  <span>Date of birth </span> {postInfo.dateOfBirth}{" "}
                </p>
              </div>
            </div>
            <div className="gridTwo">
              <div className="postDetailsDescTwoLeft">
                <p className="my-1">
                  <span>Medical History :</span> {postInfo.medicalHistory}{" "}
                </p>
                <p className="my-1">
                  {" "}
                  <span>Dental History :</span> {postInfo.dentalHistory}
                </p>
                <p className="my-1">
                  <span>Daily Medication :</span> {postInfo.dailyMedications}{" "}
                </p>

                <p className="my-1">
                  <span>
                    Concerned teeth{" "}
                    <Icon
                      icon="fluent:info-24-regular"
                      onClick={handleOpen}
                      className="concernedTeethIconInfo"
                    />{" "}
                  </span>{" "}
                  {postInfo?.concernedTeeth?.trim()}{" "}
                </p>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={TeethStyle}>
                  <img
                    src={teethNumbers}
                    alt="teeth"
                    style={{ width: "30em", textAlign: "center" }}
                  />
                </Box>
              </Modal>
              <div className="postDetailsDescTwoRight">
                <p className="my-1">
                  {" "}
                  <span>Blood Pressure </span>{" "}
                  <i
                    class="fa-solid fa-heart-pulse"
                    style={{ color: "#4e9ec6" }}
                  ></i>{" "}
                  {postInfo.bloodPressure} mm Hg
                </p>
                <div style={{ width: "50%" }}>
                  {" "}
                  <BloodPressureLinearProgress
                    variant="determinate"
                    value={(postInfo.bloodPressure * 100) / 180}
                  />
                </div>
                <p className="my-1">
                  <span>Pulse </span>{" "}
                  <i
                    class="fa-solid fa-droplet"
                    style={{ color: "#4e9ec6" }}
                  ></i>{" "}
                  {postInfo.pulse} /min
                </p>
                <div style={{ width: "50%" }}>
                  {" "}
                  <PulseLinearProgress
                    variant="determinate"
                    value={(postInfo.pulse * 100) / 82 - 30}
                  />
                </div>
                <p className="my-1">
                  <span>Breathing </span>{" "}
                  <i class="fa-solid fa-lungs" style={{ color: "#4e9ec6" }}></i>{" "}
                  {postInfo.respiration} /min
                </p>
                <div style={{ width: "50%" }}>
                  {" "}
                  <RespirationLinearProgress
                    variant="determinate"
                    value={(postInfo.respiration * 100) / 45}
                  />
                </div>
              </div>
            </div>
            <div className="gridThree">
              <div className="postDetailsExamsOne">
                <h3>General Examinations</h3>
                <p className="my-1">
                  <span>Extraoral Examination :</span>{" "}
                  {postInfo.extraoralExamination}{" "}
                </p>
                <p className="my-1">
                  <span>Intraoral Examination :</span>{" "}
                  {postInfo.intraoralExamination}{" "}
                </p>
                <p className="my-1">
                  <span>Exo-buccal Examination :</span> {postInfo.examenExoBuccal}{" "}
                </p>
                <p className="my-1">
                  <span>Dermato :</span> {postInfo.dermato}{" "}
                </p>
                <p className="my-1">
                  <span>Symétrie :</span>{" "}
                  {postInfo.symetrie === "ouiSymetrie" ? (
                    <CheckCircleIcon />
                  ) : (
                    <UnpublishedIcon />
                  )}{" "}
                </p>

                {postInfo.symetrie === "ouiSymetrie" && (
                  <p className="my-1">
                    <span>Détails Symétrie :</span>{" "}
                    {postInfo.symetrieExplanation}{" "}
                  </p>
                )}
              </div>
              <div className="postDetailsExamsTwo">
                <h3>
                  <span></span>Examen des ATM
                </h3>

                {postInfo.examenAtmNormal === "true" && (
                  <p className="my-1">
                    <span>Normal :</span>{" "}
                    {postInfo.examenAtmNormal === "true" ? (
                      <CheckCircleIcon />
                    ) : (
                      <UnpublishedIcon />
                    )}
                  </p>
                )}

                {postInfo.examenAtmDouleur === "true" && (
                  <p className="my-1">
                    <span>Douleur :</span>{" "}
                    {postInfo.examenAtmDouleur === "true" ? (
                      <CheckCircleIcon />
                    ) : (
                      <UnpublishedIcon />
                    )}
                  </p>
                )}

                {postInfo.examenAtmClaquement === "true" && (
                  <p className="my-1">
                    <span>Claquement :</span>{" "}
                    {postInfo.examenAtmClaquement === "true" ? (
                      <CheckCircleIcon />
                    ) : (
                      <UnpublishedIcon />
                    )}
                  </p>
                )}

                {postInfo.examenAtmAutre === "true" && (
                  <p className="my-1">
                    <span>Autres :</span>{" "}
                    {postInfo.examenAtmAutre === "true" ? (
                      <CheckCircleIcon />
                    ) : (
                      <UnpublishedIcon />
                    )}
                  </p>
                )}

                {postInfo.examenAtmAutre.length > 1 && (
                  <p className="my-1">
                    <span>Explication :</span>{" "}
                    {postInfo.examenAtmAutreExplanation}{" "}
                  </p>
                )}
              </div>
              <div className="postDetailsExamsThree">
                <h3>Examens Fonctionnels</h3>
                <h4>Respiration</h4>

                <p className="my-1">
                  <span>Type :</span> {postInfo.respirationType}{" "}
                </p>

                {postInfo.detailsRespiration.length > 1 && (
                  <p className="my-1">
                    <span>Détails :</span> {postInfo.detailsRespiration}{" "}
                  </p>
                )}

                <h4>Mastication</h4>
                <p className="my-1">
                  <span>Type :</span> {postInfo.mastication}{" "}
                </p>

                {postInfo.detailsMastication.length > 1 && (
                  <p className="my-1">
                    <span>Détails :</span> {postInfo.detailsMastication}{" "}
                  </p>
                )}

                <h4>Déglutition</h4>

                <p className="my-1">
                  <span>Type :</span> {postInfo.deglutition}{" "}
                </p>
                {postInfo.detailsDeglutition.length > 1 && (
                  <p className="my-1">
                    <span>Détails :</span> {postInfo.detailsDeglutition}{" "}
                  </p>
                )}
              </div>
            </div>
            <p className="my-1 postImagesContainer">
              {postInfo.postImage && (
                <div>
                  {/* <img
                    className="postImage"
                    src={`${
                      process.env.REACT_APP_BASE_URL + "/" + postInfo.postImage
                    }`}
                    alt="dentistUpProfilePicture"
                  /> */}

                  {/* <ImageGroup>
                    <ul className="images">
                      {images.map((i) => (
                        <li key={i}>
                          <Image
                            src={i}
                            alt="dentistup"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </ImageGroup> */}
                </div>
              )}
            </p>
          </div>
        </div>
      </div>
      {auth?.user?.verified === "accepted" &&  <div id="commentSectionPost">
      <Container>
     <Row>
          
        <Col sm={12}>
          <Card>
            <h4>Chat Bot Reply</h4>
            <p className="my-1" style={{fontWeight: "bold"}}>
            HyperWrite's Boot Responder is an AI-powered tool that takes a received email and a shorthand response to generate a professional,
well-written email reply. Leveraging the powerful GPT-4 and ChatGPT AI models, this tool helps you save time and maintain a polished communication style in your email correspondence.‍
            </p>
          </Card>
          <Card>
            <Card.Body className=" profile-page p-0">
              <div className="postDetailsCommentsContainer">
                <CommentForm postId={id} getPost={getPost}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <ul className="post-comments list-inline p-0 m-0">
          {postDetails.comments.map((comment) => (
         
              <Card>
                <Card.Body className=" profile-page p-0">
                  <CommentItem
                    key={comment._id}
                    comment={comment}
                    postId={id}
                    match={match}
                    getPost={getPost}
                  />
                </Card.Body>
              </Card>
           
          ))}
        </ul>
     
        </Row>
     </Container>
      </div>}
     
   
    
      
    </Fragment>
  );
};

PostDetails.propTypes = {
  getPosts: PropTypes.func.isRequired,
  getPost: PropTypes.func.isRequired,

  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  savePost: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPosts, getPost,   addLike,
  removeLike,
  deletePost,
  savePost,
  loadUser, })(PostDetails);
