import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addLike, removeLike, deletePost } from "../../../actions/post";
import { savePost } from "../../../actions/profile";
import { loadUser } from "../../../actions/auth";

import FormWizard from "../from/form-wizard";
// import PostDetails from "./PostDetails";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// import { styled, alpha } from "@mui/material/styles";
import ShareOffcanvas from "../../../components/share-offcanvas";
import CustomToggle from "../../../components/dropdowns";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import EditIcon from "@mui/icons-material/Edit";
// import Divider from "@mui/material/Divider";
// import DeleteIcon from "@mui/icons-material/Delete";
// import IconButton from "@mui/material/IconButton";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import EditPost from "./EditPost";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Card, Dropdown, OverlayTrigger, Tooltip, Col } from "react-bootstrap";
import user01 from "../../../assets/images/user/01.jpg";
// Import other required images and components

import CommentItem from "./CommentItem";

import user1 from "../../../assets/images/user/1.jpg";

import user2 from "../../../assets/images/user/02.jpg";
import user3 from "../../../assets/images/user/03.jpg";
import user4 from "../../../assets/images/user/04.jpg";
import img1 from "../../../assets/images/small/07.png";
import img2 from "../../../assets/images/small/08.png";
import img3 from "../../../assets/images/small/09.png";
import img4 from "../../../assets/images/small/10.png";
import img5 from "../../../assets/images/small/11.png";
import img6 from "../../../assets/images/small/12.png";
import img7 from "../../../assets/images/small/13.png";
import img8 from "../../../assets/images/small/14.png";
import p1 from "../../../assets/images/page-img/p1.jpg";
import s1 from "../../../assets/images/page-img/s1.jpg";
import s2 from "../../../assets/images/page-img/s2.jpg";
import s3 from "../../../assets/images/page-img/s3.jpg";
import s4 from "../../../assets/images/page-img/s4.jpg";
import s5 from "../../../assets/images/page-img/s5.jpg";
import p2 from "../../../assets/images/page-img/p2.jpg";
import p3 from "../../../assets/images/page-img/p3.jpg";
import p4 from "../../../assets/images/page-img/p4.jpg";
import p5 from "../../../assets/images/page-img/p5.jpg";
import img42 from "../../../assets/images/page-img/42.png";
import icon1 from "../../../assets/images/icon/01.png";
import notliked from "../../../assets/images/icon/notliked.png";
import icon2 from "../../../assets/images/icon/02.png";
import icon3 from "../../../assets/images/icon/03.png";
import icon4 from "../../../assets/images/icon/04.png";
import icon5 from "../../../assets/images/icon/05.png";
import icon6 from "../../../assets/images/icon/06.png";
import icon7 from "../../../assets/images/icon/07.png";
import img9 from "../../../assets/images/small/img-1.jpg";
import img10 from "../../../assets/images/small/img-2.jpg";
import loader from "../../../assets/images/page-img/page-load-loader.gif";

const PostItemGaza = ({
  addLike,
  removeLike,
  deletePost,
  savePost,
  loadUser,
  auth,
  post: {
    _id,
    postInfo,
    name,
    avatar,
    user,
    likes,
    comments,
    date,
    profileImage,
  },
  showActions,
  showDetails,
  showUserPosts,
  postsByUserId,
  getPost,
  match,
  showSavedCases,
  savedPostsIds,
}) => {

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80em",
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
   
    height: "80%",
    overflowY: 'scroll', 
  };
  const [openEditPost, setOpenEditPost] = React.useState(false);
  const handleOpenEditPost = () => setOpenEditPost(true);
  const handleCloseEditPost = () => setOpenEditPost(false);

  const postImages = postInfo?.postImages;
  const firstAvailableImage = postImages && Object.values(postImages).find(imagePath => imagePath !== null && imagePath !== undefined);
  




    const [orientedImage, setOrientedImage] = useState(null);
    useEffect(() => {
      const img = new Image();
      img.src = `${
        process.env.REACT_APP_BASE_URL + "/" + firstAvailableImage
      }`;
      img.setAttribute('crossorigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        const width = img.width;
        const height = img.height;
  
        // Determine if the image is in portrait orientation
        if (width > height) {
          canvas.width = width;
          canvas.height = height;
        } else {
          canvas.width = height;
          canvas.height = width;
          ctx.translate(height, 0);
          ctx.rotate(Math.PI / 2); // Rotate the canvas 90 degrees if it's in portrait orientation
        }
  
        ctx.drawImage(img, 0, 0);
  
        // Convert the canvas to a data URL
        const orientedSrc = canvas.toDataURL('image/png');
  
        setOrientedImage(orientedSrc);
      };
    }, [firstAvailableImage]);
  
    // return (
    //   <div className={`image-rotator ${orientation === 'portrait' ? 'rotate' : ''}`}>
    //     <img src={src} alt="Image" />
    //   </div>
    // );
  


  const momentDate = moment.utc(date.replace("T", " ").replace("Z", ""));
  const currentMoment = moment();
  
  // Calculate the difference in hours between the moment and the current time
  const hoursDifference = currentMoment.diff(momentDate, 'hours');

if(!_id){
  return <h5>loading</h5>
}
  return (
    <Col sm={12}>
      <Card className=" card-block card-stretch card-height">
        <Card.Body>
          <div className="user-post-data">
            <div className="d-flex justify-content-between">
              <div className="me-3">
                {/* <img className="rounded-circle img-fluid" src={user01} alt=""/> */}
                <img
                  src={
                    profileImage
                      ? `${process.env.REACT_APP_BASE_URL + "/" + profileImage}`
                      : avatar
                  }
                  alt="Dentistup"
                  className="rounded-circle img-fluid round-img"
                  style={{ width: 60 }}
                />
              </div>
              <div className="w-100">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="mb-0 d-inline-block" >
                      {" "}
                      <Link to={`/dashboard/app/profile-feed/${user}`} style={{color: "#454545"}}>
                        {name}
                      </Link>
                    </h5>
                    {hoursDifference <= 24 &&  <span className="mb-0 ps-1 d-inline-block" style={{fontStyle:"italic", fontSize:"small"}}>
                      Added a New Post
                    </span>}
                   
                    <p className="mb-0" style={{color: "#606060e0"}}>
                      {moment
                        .utc(date.replace("T", " ").replace("Z", ""))
                        .local()
                        .startOf("seconds")
                        .fromNow()}
                    </p>
                  </div>
                  
                  {auth?.user?.verified === "accepted" && <div className="card-post-toolbar">
                    <Dropdown>
                      <Dropdown.Toggle variant="bg-transparent">
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                        <Dropdown.Item
                          className=" p-3"
                          to="#"
                          onClick={() => {
                            savePost(_id);
                            loadUser();
                            loadUser();
                          }}
                        >
                          {_id &&
                            auth?.user?.savedPosts?.filter(
                              (savedPost) => savedPost?.post?.toString() === _id
                            ).length === 0 && (
                              <div className="d-flex align-items-top">
                                <div className="h4 material-symbols-outlined">
                                  <i className="ri-save-line"></i>
                                </div>
                                <div className="data ms-2">
                                  <h6>Save Post</h6>
                                  <p className="mb-0">
                                    Add this to your saved items
                                  </p>
                                </div>
                              </div>
                            )}

                          {_id &&
                            auth?.user?.savedPosts?.filter(
                              (savedPost) => savedPost?.post?.toString() === _id
                            ).length !== 0 && (
                              <div className="d-flex align-items-top">
                                <div className="h4 material-symbols-outlined">
                                  <i className="ri-save-line"></i>
                                </div>
                                <div className="data ms-2">
                                  <h6>Unsave Post</h6>
                                  <p className="mb-0">
                                    Remove this from your saved items
                                  </p>
                                </div>
                              </div>
                            )}
                        </Dropdown.Item>

                        { user === auth?.user?._id  && 
                        <Dropdown.Item className="p-3" to="#"   onClick={() => {
                          handleOpenEditPost(_id);
                        
                        }}>
                          <div className="d-flex align-items-top">
                            <i className="ri-edit-line h4"></i>
                            <div className="data ms-2">
                              <h6>Edit Post</h6>
                              <p className="mb-0">Edit your post.</p>
                            </div>
                          </div>
                        </Dropdown.Item>}


                        { user === auth?.user?._id  && 
                        <Dropdown.Item className="p-3" to="#"   onClick={() => {
                          deletePost(_id);
                        
                        }}>
                          <div className="d-flex align-items-top">
                            <i className="ri-close-circle-line h4"></i>
                            <div className="data ms-2">
                              <h6>Delete Post</h6>
                              <p className="mb-0">Delete your post.</p>
                            </div>
                          </div>
                        </Dropdown.Item>}




                        <Modal
        open={openEditPost}
        onClose={handleCloseEditPost}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <FormWizard
            _id={_id}
            postInfo={postInfo}
            getPost={getPost}
            match={match}
            handleCloseEditPost={handleCloseEditPost}
            editPostMode={true}
          />
          
        </Box>
      </Modal>
                      
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <h4 className="mt-3">
            <Link to={`/dashboard/app/post-details-gaza/${_id}`}>
              {postInfo.title}
            </Link>
          </h4>
          <div>
            <p>
              {" "}
              {postInfo.description?.slice(0, 200) +
                (postInfo.description?.length > 200 ? "..." : "")}
            </p>
          </div>
          <div className="postIconsInfo">
            {postInfo.gender === "male" && (
              <i class="fa-solid fa-mars" style={{ color: "#4e9ec6" }}></i>
            )}
            {postInfo.gender === "female" && (
              <i class="fa-solid fa-venus" style={{ color: "#f98196" }}></i>
            )}
            <p>{getAge(postInfo.dateOfBirth)} </p>

         
          </div>
          {/* {postInfo.postImage && <div className="user-post">
            <div className=" d-grid grid-rows-2 grid-flow-col gap-3">
              <div className="row-span-2 row-span-md-1">
             
                <img
                  src={`${
                    process.env.REACT_APP_BASE_URL + "/" + postInfo.postImage
                  }`}
                  alt="dentistup"
                  className="img-fluid rounded w-100"
                />
              </div>

              </div>
          </div>} */}


{firstAvailableImage  && (
  <div className="user-post">
    <div className="d-grid grid-rows-2 grid-flow-col gap-3">
      <div  className="row-span-2 row-span-md-1">
        <img
        crossorigin="anonymous"
          src={`${
             orientedImage
          }`}
          alt="dentistup"
       
          className="img-fluid rounded w-100"
        
        />
      </div>
    </div>
  </div>
)}



              {/* <div className="row-span-1">
                            <img src={p1} alt="post2" className="img-fluid rounded w-100"/>
                            <img
                        src={`${
                          process.env.REACT_APP_BASE_URL +
                          "/" +
                          postInfo.postImage
                        }`}
                        alt="dentistup"
                        className="img-fluid rounded w-100"
                      />
                        </div>
                        <div className="row-span-1 ">
                            <img src={p3} alt="post3" className="img-fluid rounded w-100"/>
                            <img
                        src={`${
                          process.env.REACT_APP_BASE_URL +
                          "/" +
                          postInfo.postImage
                        }`}
                        alt="dentistup"
                        className="img-fluid rounded w-100"
                      />
                        </div> */}
          
          {auth?.user?.verified === "accepted" &&   <div className="comment-area mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="like-block position-relative d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="like-data">
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        onClick={() => {
                          addLike(_id);
                          loadUser();
                          loadUser();
                        
                        }}
                      >
                        <i class={
                            likes?.filter(
                              (like) =>
                                like?.user?.toString() === auth?.user?._id
                            ).length !== 0
                              ? "fa-solid fa-heart"
                              : "fa-regular fa-heart"
                          } style={{fontSize: "1.5em"}}></i>
                     
                      </Dropdown.Toggle>
                   
                    </Dropdown>
                  </div>
                  <div className="total-like-block ms-2 me-3">
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} id="post-option">
                        {likes.length} Likes
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        
                        {likes.map((like) => (
                          <Dropdown.Item key={like.user} href={`/dashboard/app/profile-feed/${like.user}`}>
                            {like.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="total-comment-block">
                  
                  <Dropdown>
                  <i class= "fa-regular fa-comment" style={{fontSize: "1.5em", marginRight: "0.4em"}}></i>
                    <Dropdown.Toggle as={CustomToggle} id="post-option">
                      
                      {comments.length} Comment
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item href="#">Max Emum</Dropdown.Item>
                      <Dropdown.Item href="#">Bill Yerds</Dropdown.Item>
                      <Dropdown.Item href="#">Hap E. Birthday</Dropdown.Item>
                      <Dropdown.Item href="#">Tara Misu</Dropdown.Item>
                      <Dropdown.Item href="#">Midge Itz</Dropdown.Item>
                      <Dropdown.Item href="#">Sal Vidge</Dropdown.Item>
                      <Dropdown.Item href="#">Other</Dropdown.Item> */}
                       {comments.map((comment) => (
                          <Dropdown.Item key={comment.user} href={`/dashboard/app/profile-feed/${comment.user}`}>
                            {comment.name}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {/* <ShareOffcanvas /> */}
            </div>
            <hr />
            <ul className="post-comments list-inline p-0 m-0">
              {comments.map((comment) => (
                <CommentItem
                  key={comment._id}
                  comment={comment}
                  postId={_id.toString()}
                  getPost={getPost}
                  match={match}
                />
              ))}
    
            </ul>
      
          </div>}
        
        </Card.Body>
      </Card>
    </Col>
  );
};

PostItemGaza.defaultProps = {
  showActions: true,
  showDetails: true,
  showUserPosts: false,
  showSavedCases: false,
};

PostItemGaza.propTypes = {
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  savePost: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addLike,
  removeLike,
  deletePost,
  savePost,
  loadUser,
})(PostItemGaza);
