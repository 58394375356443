import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, Dropdown, Button, OverlayTrigger,Modal, Tooltip,Form } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { connect } from "react-redux";
//image
import img1 from '../../../assets/images/page-img/gi-1.jpg'
import small1 from '../../../assets/images/small/07.png'
import header from '../../../assets/images/page-img/profile-bg7.jpg'
import img7 from "../../../assets/images/page-img/profile-bg7.jpg";
import img3 from "../../../assets/images/page-img/profile-bg3.jpg";
import groupimg from "../../../assets/images/page-img/group-img.jpg";

import PostItem from "../posts/PostItem";
import PostItemGaza from "../posts/PostItemGaza";
import { getGroup, deleteGroup, acceptRequest,rejectRequest } from "../../../actions/group";
import { getPosts } from "../../../actions/post";
import FormWizard from "../from/form-wizard";
import FormWizardGaza from "../from/form-wizard-gaza";
import GroupProfileItem from "./profileItem/GroupProfileItem";
import ProfileItem from "./profileItem/ProfileItem";
import CreateGroup from "./createGroup";
const GroupDetail = ({getGroup, deleteGroup, group, auth, getPosts, profile: { profiles, loading }}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showRequests, setShowRequests] = useState(false);
    const handleCloseRequests = () => setShowRequests(false);
    const handleShowRequests = () => setShowRequests(true);

    const [showMembers, setShowMembers] = useState(false);
    const handleCloseMembers = () => setShowMembers(false);
    const handleShowMembers = () => setShowMembers(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);


    const { pathname } = window.location;
    const id = pathname.split("/").pop();

    localStorage
    .setItem('currentGroupId', id);

    useEffect(() => {
        getGroup(id);
      },[getGroup,id]);

      
      const posts = useSelector((state) => state.posts.posts);
      useEffect(() => {
        getPosts();
      }, [getPosts]); 
      
console.log(posts?.filter(post => post?.postInfo?.groupId === id?.toString()));

      const requestProfiles = profiles?.filter(
        (profile) =>
          profile?.user &&
          profile?.user?._id?.includes(group?.pendingRequests)
      );

      const memberProfiles = profiles?.filter(
        (profile) =>
          profile?.user &&
          profile?.user?._id?.includes(group?.members)
      );


    return (
        <>
             <ProfileHeader  img={
                    group?.groupInfo?.groupImages?.groupbackgroundimage
                      ? `${process.env.REACT_APP_BASE_URL + "/" + group?.groupInfo?.groupImages?.groupbackgroundimage}`
                      : img3
                  } title="Groups" />
                <div id="content-page" className="content-page">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                    <div className="group-info d-flex align-items-center">
                                        <div className="me-3">
                                            <img className="rounded-circle img-fluid avatar-100" src={
                    group?.groupInfo?.groupImages?.groupimage
                      ? `${process.env.REACT_APP_BASE_URL + "/" + group?.groupInfo?.groupImages?.groupimage}`
                      : groupimg
                  } alt=""/>
                                        </div>
                                        <div className="info">
                                            <h4>{group?.groupInfo?.title}</h4>
                                            <p className="mb-0"><i className="ri-lock-fill pe-2"></i>{group?.groupInfo?.privacy} Group . {group?.members?.length} members</p>
                                        </div>
                                    </div>
                                    <div mt-md="0" mt="2" className="group-member d-flex align-items-center">
                                        <div className="iq-media-group me-3">
                                        {requestProfiles.length > 0 && group?.members?.length > 0 ? (
              memberProfiles.map(
                (profile) =>
                  profile.user && (
                    <Link to="#" className="iq-media">
                    <img className="img-fluid avatar-40 rounded-circle" src={
                          profile?.profileImage
                            ? `${
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                profile?.profileImage
                              }`
                            : profile?.user?.avatar
                        } alt=""/>
                </Link>
                  )
              )
            ) : (
                <div></div>
            
            )}
                                          
                                     
                                        </div>
                                        <Button onClick={handleShowMembers} variant="primary" className="mb-2">View Members</Button>



                                        <Modal show={showEdit} onHide={handleCloseEdit} size="lg" style={{ marginTop: "9rem"}}>
                                       
                                       <Modal.Body>
                                      <CreateGroup editGroupMode={true} groupToEdit={group?.groupInfo} groupToEditId={group?._id}/>
                                       </Modal.Body>
                                   </Modal>


                                        <Modal show={showMembers} onHide={handleCloseMembers} size="lg" style={{ marginTop: "9rem"}}>
                                       
                                       <Modal.Body>
                                       {memberProfiles.length > 0 && group?.members?.length > 0 ? (
              memberProfiles.map(
                (profile) =>
                  profile.user && (
                    <ProfileItem key={profile._id} profile={profile} groupId={group._id}/>
                  )
              )
            ) : (
                <h4>No Members found...</h4>
            )}
                                       </Modal.Body>
                                   </Modal>


                                        <Modal show={showRequests} onHide={handleCloseRequests} size="lg" style={{ marginTop: "9rem"}}>
                                       
                                       <Modal.Body>
                                       {requestProfiles.length > 0 && group?.pendingRequests?.length > 0 ? (
              requestProfiles.map(
                (profile) =>
                  profile.user && (
                    <GroupProfileItem key={profile._id} profile={profile} groupId={group._id} getGroup={getGroup}/>
                  )
              )
            ) : (
                <h4>No Requests found...</h4>
            )}
                                       </Modal.Body>
                                   </Modal>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="8">
                                <Card id="post-modal-data">
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Create Post</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="user-img">
                                                <img  src={
                    auth?.profileImage
                      ? `${process.env.REACT_APP_BASE_URL + "/" + auth?.profileImage}`
                      : auth?.user?.avatar
                  } alt="userimg" className="avatar-60 rounded-circle"/>
                                            </div>
                                            {/* <form className="post-text ms-3 w-100 " onClick={handleShow}>
                                                <input type="text" className="form-control rounded" placeholder="Write something here..." style={{border: "none"}}/>
                                            </form> */}
                                   
                                   <Link className="search-link" 
            //                        to={{
            //     pathname: group?.groupInfo?.title?.toLowerCase()?.includes("palestine") || group?.groupInfo?.title?.toLowerCase()?.includes("gaza") ? "/dashboard/form/form-wizard-gaza/" : "/dashboard/form/form-wizard/"
                
            //   }}>
                             to="/dashboard/form/form-wizard/">
                      <form
                        className="post-text ms-3 w-100 "
                        // onClick={handleShow}
                      >
                        <input
                          type="text"
                          className="form-control rounded"
                          placeholder="Write something here..."
                          style={{ border: "none" }}
                          
                        />
                      </form>
                      </Link>
                                        </div>
                                        <hr/>
                                        <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                                <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2">
                                                    <img src={small1} alt="icon" className="img-fluid me-2"/> Photo/Video
                                                </li>
                                          
                                            
                                            </ul>
                                    </Card.Body>
                                    <Modal show={show} onHide={handleClose} size="lg"  id="post-modal">
                                    <Modal.Header className="d-flex justify-content-between">
                        <Modal.Title id="post-modalLabel">
                          Create Post
                        </Modal.Title>
                        <Link
                          to="#"
                          className="lh-1"
                          onClick={handleClose}
                        >
                          <span className="material-symbols-outlined">
                            close
                          </span>
                        </Link>
                      </Modal.Header>
                      <Modal.Body>
  {/* {group?.groupInfo?.title?.toLowerCase()?.includes("palestine") || group?.groupInfo?.title?.toLowerCase()?.includes("gaza") ? (
      <FormWizardGaza groupId={id} />
   
  ) : (
    <FormWizard groupId={id} />
  )} */}
   <FormWizard groupId={id} />
</Modal.Body>


                                    </Modal>
                                </Card>
                                <Card>
                                    <Card.Body>
                                    {posts?.map((post) => {
               
               if (post?.postInfo?.visibility !== 'private' && post?.postInfo?.groupId?.toString() === id?.toString()) {
return <PostItem key={post._id} post={post} showDetails={false} />
              
                //  return group?.groupInfo?.title?.toLowerCase()?.includes("palestine") || group?.groupInfo?.title?.toLowerCase()?.includes("gaza") ? <PostItemGaza key={post._id} post={post} showDetails={false} /> : <PostItem key={post._id} post={post} showDetails={false} />;
               }
               return null; // Skip private posts
             })}


             
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card>
                                    <Card.Header className="card-header d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Groups</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <ul className="list-inline p-0 m-0">
                                            {/* <li className="mb-3 pb-3 border-bottom">
                                                <div className="iq-search-bar members-search p-0">
                                                    <form action="#" className="searchbox w-auto">
                                                        <input type="text" className="text search-input bg-grey" placeholder="Type here to search..."/>
                                                        <Link className="search-link" to="#"><i className="ri-search-line"></i></Link>
                                                    </form>
                                                </div>
                                            </li> */}
                                            <li className="mb-3 d-flex align-items-center">
                                                <div className="avatar-40 rounded-circle bg-gray d-flex align-items-center justify-content-center me-3">
                                                    <i className="material-symbols-outlined">credit_card</i>
                                                </div>
                                                <h6 className="mb-0">Your Feed</h6>
                                            </li>
                                            <li className="mb-3 d-flex align-items-center">
                                                <div className="avatar-40 rounded-circle bg-gray d-flex align-items-center justify-content-center me-3">
                                                    <i className="material-symbols-outlined">explore</i>
                                                </div>
                                                <h6 className="mb-0">Discover</h6>
                                            </li>
                                            { group?.user === auth?.user?._id  && 
                         <li>
                            <button  onClick={handleShowRequests} className="btn btn-primary d-block w-100">View Requests</button>
                            
                            <button style={{margin: "1em 0"}} onClick={handleShowEdit} className="btn btn-success d-block w-100">Edit Group</button>
                           
                         <button type="submit" onClick={(e) => {
                            e.preventDefault();
                          deleteGroup(id);
    
                        }} className="btn btn-danger d-block w-100">
                            Delete Group
                         </button>
                         
                     </li> }
                                          
                                        </ul>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header className="card-header d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">About</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <ul className="list-inline p-0 m-0">
                                            <li className="mb-3">
                                                <p className="mb-0">{group?.groupInfo?.description}</p>
                                            </li>
                                            <li className="mb-3">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-symbols-outlined">lock</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6>Private</h6>
                                                        <p className="mb-0">Success in slowing economic activity.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="mb-3">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-symbols-outlined">visibility</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6>Visible</h6>
                                                        <p className="mb-0">Various versions have evolved over the years</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li >
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-symbols-outlined">group</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6>General group</h6>
                                                        <p className="mb-0">There are many variations of passages</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    </div> 
        </>
    )
}

GroupDetail.propTypes = {
    getGroup: PropTypes.func.isRequired,
    deleteGroup: PropTypes.func.isRequired,
    group: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    post: PropTypes?.object?.isRequired,
    getPosts: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
  };
  const mapStateToProps = (state) => ({
    group: state.group.group,
    auth: state.auth,
    post: state?.post,
    profile: state.profile,
  });
  export default connect(mapStateToProps, {
    getGroup,
    deleteGroup,
    getPosts,
  })(GroupDetail);
